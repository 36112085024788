import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem } from "@material-ui/core";

import {
  NumberInputFieldComponent,
  NumberZipCodeInputFieldComponent,
  TextInputFieldComponent,
  BackButton,
  SaveButton
} from "../../../shared/components";
import { RegionsRepository } from "../../../shared/repositories";
import { RegionModel } from "../../../shared/models";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    }
  }
}));

export function RegionsDetailComponent({ update }) {
  const classes = useStyles();
  const [region, setRegion] = useState({});
  const regionRepository = new RegionsRepository();
  const { drawerValue, closeDrawerRight } = useDrawerRightContext();

  const saveRegion = _ => {
    const regionModel = new RegionModel(region);
    return regionRepository[regionModel.id ? "update" : "create"](regionModel.toJSON()).then(data => {
      update(data)
      setRegion(data.toState())
    })
  }

  useEffect(() => {
    setRegion(drawerValue)
  }, [drawerValue])

  const handleChange = name => event => {
    setRegion({ ...region, [name]: event.target.value });
  };

  const goBack = () => {
    closeDrawerRight()
  }

  return (
    <Grid className={classes.root}>
      <List>
        <ListItem>
          <Typography variant="h6" gutterBottom>Dados da Região</Typography>
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="name"
            label="Nome"
            value={region.name}
            onChange={handleChange('name')}
          />
        </ListItem >
        <ListItem>
          <NumberZipCodeInputFieldComponent
            id="beginningZipcode"
            label="CEP Inicio"
            value={region.beginningZipcode}
            onChange={handleChange('beginningZipcode')}
          />
        </ListItem >
        <ListItem>
          <NumberZipCodeInputFieldComponent
            id="endZipcode"
            label="CEP fim"
            value={region.endZipcode}
            onChange={handleChange('endZipcode')}
          />
        </ListItem >
        <ListItem>
          <NumberInputFieldComponent
            id="maxWeightInDay"
            label="Max Peso / Dia"
            value={region.maxWeightInDay}
            onChange={handleChange('maxWeightInDay')}
            decimalSeparator=","
            thousandSeparator="."
            suffix=" Kg"
            customInput={TextInputFieldComponent}
          />
        </ListItem >
        <ListItem style={{ display: "flex", justifyContent: "space-between" }}>
          <BackButton onClick={goBack} />
          <SaveButton onClick={saveRegion} />
        </ListItem >
      </List >
    </Grid >
  );

}

