import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem, MenuItem } from "@material-ui/core";


import {
  TextInputFieldComponent,
  BackButton,
  SaveButton,
  DateTimeInputFieldComponent
} from "../../../shared/components";
import { SectorsRepository, SeparationsRepository, UsersRepository } from "../../../shared/repositories";
import { SeparationModel } from "../../../shared/models";
import { OrderItemSeparationComponent } from "./order_item_separations";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    }
  },
  dateField: {
    paddingRight: theme.spacing(3),
  },
}));

export function SeparationsDetailComponent({ update }) {
  const classes = useStyles();
  const [separationDetails, setSeparationDetails] = React.useState({});
  const [sectors, setSectors] = React.useState([]);
  const [separators, setSeparators] = React.useState([]);
  const separationRepository = new SeparationsRepository();
  const sectorsRepository = new SectorsRepository();
  const usersRepository = new UsersRepository();
  const { drawerValue, closeDrawerRight } = useDrawerRightContext();

  const saveSeparation = _ => {
    const separationModel = new SeparationModel(separationDetails);
    separationRepository.update(separationModel.toJSON()).then(result => {
      update(result)
      setSeparationDetails(result.toState())
    })
  }

  React.useEffect(() => {
    setSeparationDetails(drawerValue)
    if (sectors.length === 0)
      sectorsRepository.getAll({ perPage: 100 }).then(result => {
        if (result.total > 0)
          setSectors(result.data)
      })
    if (separators.length === 0)
      usersRepository.getAll({ queryParams: 'roles=separator', perPage: 100 }).then(result => {
        if (result.total > 0)
          setSeparators(result.data)
      })
  }, [drawerValue])

  const handleChange = name => event => {
    setSeparationDetails({ ...separationDetails, [name]: event.target.value });
  };

  const handleDateChange = name => event => {
    if (event)
      setSeparationDetails({ ...separationDetails, [name]: event._d });
  };

  const goBack = () => {
    closeDrawerRight()
  }

  const handleChangeOrderItemSeparations = (orderItemSeparations) => {
    setSeparationDetails({ ...separationDetails, orderItemSeparations });
  }

  return (
    <Grid className={classes.root}>
      <List>
        <ListItem>
          <Typography variant="h6" gutterBottom>Dados da Separação</Typography>
        </ListItem>
        <ListItem>
          <TextInputFieldComponent
            id="legacyOrderId"
            label="Pedido"
            value={separationDetails.legacyOrderId}
            readOnly
          />
        </ListItem>
        <ListItem>
          <TextInputFieldComponent
            id="orderCreatedAt"
            label="Data do Pedido"
            value={separationDetails.orderCreatedAt}
            readOnly
          />
        </ListItem>
        <ListItem>
          <TextInputFieldComponent
            id="sector"
            select
            label="Setor"
            value={separationDetails.sectorId}
            onChange={handleChange("sectorId")}>
            {sectors.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem>
        <ListItem>
          <TextInputFieldComponent
            id="separator"
            select
            label="Separador"
            value={separationDetails.separatorId}
            onChange={handleChange("separatorId")}>
            {separators.filter((option) => option.sectors.map(s => s.id).includes(separationDetails.sectorId)).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem>
        {separationDetails.reason && <ListItem>
          <TextInputFieldComponent
            id="reason-select"
            label="Motivo"
            value={separationDetails.reason}
            onChange={handleChange('reason')}
          />
        </ListItem>}
        <ListItem className={classes.dateField}>
          <DateTimeInputFieldComponent
            id="startedAt"
            label="Iniciado em"
            value={separationDetails.startedAt}
            error={false}
            className={classes.textField}
            helperText={null}
            onChange={handleDateChange("startedAt")}
          />
        </ListItem>
        <ListItem className={classes.dateField}>
          <DateTimeInputFieldComponent
            id="finishedAt"
            label="Data fim"
            value={separationDetails.finishedAt}
            error={false}
            className={classes.textField}
            helperText={null}
            onChange={handleDateChange("finishedAt")}
          />
        </ListItem>
        <ListItem>
          <OrderItemSeparationComponent orderItemSeparations={separationDetails.orderItemSeparations} handleChangeModel={handleChangeOrderItemSeparations} />
        </ListItem>
        <ListItem style={{ display: "flex", justifyContent: "space-between" }}>
          <BackButton onClick={goBack} />
          <SaveButton onClick={saveSeparation} />
        </ListItem>
      </List >
    </Grid >
  );

}

