import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  badge: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      width: 10,
      height: 10,
      borderRadius: '50%',
      marginRight: theme.spacing(1),
    },
  },
}));


export const TextColorHelperComponent = ({ tooltip, color, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.badge}>
      <Tooltip title={tooltip || ""}>
        <span style={{ backgroundColor: color }}></span>
      </Tooltip>
      {children}
    </div>
  );
};
