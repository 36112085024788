import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton as TIconButton } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: props => props.bgColor || 'transparent',
    borderRadius: "20px",
    color: props => props.color || blue[500],
    maxHeight: "30px",
    maxWidth: "30px",
    '&:hover': {
      backgroundColor: props => props.hoverColor || blue[700],
      color: props => props.hoverBgColor || 'white',
    },
  },
}));

export function IconButton({ show, className, onClick = () => { }, disabled = false, children = <></>, color, hoverColor, bgColor, hoverBgColor, ...props }) {
  const classes = useStyles({ color, hoverColor, bgColor, hoverBgColor });

  return (
    <TIconButton
      size="small"
      {...props}
      disabled={disabled}
      onClick={onClick}
      className={clsx(classes.button, className)}
    >
      {children}
    </TIconButton>
  );
}
