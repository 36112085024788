export const paths = {
  dashboard: "/",
  clients: "/clientes",
  companies: "/empresas",
  deliveries: "/entregas",
  me: "/me",
  mePassword: "/me/password",
  reports: "/relatorios",
  restrictedDates: "/datas-bloqueadas",
  stores: "/lojas",
  users: "/usuarios",
  vehicles: "/veiculos",
  regions: "/regioes",
  separations: "/separacoes",
  separationsRealTime: "/separações/tempo-real",
  sectors: "/setores"
};
