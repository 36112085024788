import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, IconButton, Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { AuthRepository } from '../../repositories';
import { Constants } from '../../helpers';
import { NotificationsNavbarComponent } from './notificationsNavbar.component';

const useStyles = makeStyles(theme => ({
  avatar: {
    color: '#fff',
    backgroundColor: '#fff',
    padding: theme.spacing(1)
  },
  floatRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  spacing:{
    marginRight: theme.spacing(2)
  }
}));

export function RightNavbarComponent() {
  const { auth } = useSelector(state => state);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const authRepository = new AuthRepository();
  const history = useHistory();
  const classes = useStyles();

  const isMenuOpen = Boolean(anchorEl);

  const getLogo = () => {
    const company = auth.companies.find(c => c.cid === auth.cid)
    if (!company) return '/assets/img/logo.png';
    const logo = company.logo
    if (logo && logo.url)
      if (process.env.NODE_ENV === "development") {
        return `${Constants.development.apiUrl}${logo.url}`;
      } else {
        return logo.url;
      }
    return '/assets/img/logo.png'
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const signOut = () => {
    authRepository.signOut()
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disabled>{auth.name}</MenuItem>
      <MenuItem button onClick={() => { history.push(`/me`); handleMenuClose() }}>Meus Dados</MenuItem>
      <MenuItem button onClick={() => { history.push(`/me/password`); handleMenuClose() }}>Alterar Senha</MenuItem>
      <MenuItem button onClick={signOut}>Sair</MenuItem>
    </Menu>
  );

  return <div>
    <div className={clsx(classes.floatRight, classes.noWrap)}>
      <div className={classes.spacing}>
      <NotificationsNavbarComponent />
      </div>
      <IconButton edge="end" aria-controls={menuId} aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit">
        <Avatar src={getLogo()} className={classes.avatar} imgProps={{ style: { height: "auto" } }} ></Avatar>
      </IconButton>
    </div>
    {renderMenu}
  </div >
}
