import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Badge, Typography, Popper, Fade, Paper, ListItem,
  ListItemText, ListSubheader, List,
  Avatar,
  ListItemAvatar,
  Grid,
  Button,
  Divider,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { NotificationsRepository } from '../../repositories';
import { NotificationModel } from '../../models';
import { Close, LocalShipping } from '@material-ui/icons';
import { useNotificationContext } from '../../contexts/notification.context';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { paths } from '../../../Routes/paths.routes';
import { useDrawerRightContext } from '../../contexts/drawer_right.context';
import { grey, red } from '@material-ui/core/colors';
import { IconButton } from '../Buttons';
import Swal from 'sweetalert2';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    cursor: 'pointer',
  },
  title: {
    cursor: "pointer",
    display: 'block'
  },
  popper: {
    zIndex: 1282,
  },
  paper: {
    borderRadius: 4,
  },
  list: {
    overflowX: 'auto',
    maxHeight: 400,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: "95%",
    },
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  clearButton: {
    marginRight: theme.spacing(1),
    color: red[500],
  },
  deleteButton: {

    color: 'black',
    '&:hover': {
      color: red[700],
      backgroundColor: 'transparent',
    },
    alignSelf: 'flex-start',
    justifySelf: 'flex-end',
  },
  alignCenter: {
    alignSelf: 'center',
  },
  noViewed: {
    backgroundColor: red[500],
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    width: '100%',
    height: '60px',
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: grey[200],
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: grey[300],
    },
  }
}));

export function NotificationsNavbarComponent() {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const popperRef = useRef(null);
  const {
    isNotificationOpen,
    notifications,
    total,
    totalUnread,
    toggleNotificationOpen,
    viewNotification,
    nextPage,
    viewAllNotifications,
    deleteAllNotifications,
    deleteNotification,
  } = useNotificationContext();
  const { setNewDrawerValue } = useDrawerRightContext();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperRef.current && !popperRef.current.contains(event.target) && !anchorEl.contains(event.target)) {
        toggleNotificationOpen();
      }
    };

    if (isNotificationOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNotificationOpen, anchorEl]);

  useEffect(() => {
    const handleScroll = (event) => {
      const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (bottom && notifications.length < total) {
        nextPage();
      }
    };

    const listElement = document.querySelector(`.${classes.list}`);
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [notifications, total, nextPage, classes.list]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    toggleNotificationOpen();
  };

  const openNotification = async (notification) => {
    viewNotification(notification);

    switch (notification.targetType) {
      case 'Delivery':
        setNewDrawerValue(notification.targetId, window.location.pathname !== paths.deliveries);
        history.push(paths.deliveries);
        toggleNotificationOpen();
        break;

      default:
        break;
    }
  };

  const removeAllNotification = () => {
    Swal.fire({
      title: 'Excluir notificação',
      text: 'Deseja realmente excluir todas as notificações?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAllNotifications();
      }
    });
  };

  const listItemsNotification = () => {
    if (notifications && notifications.length > 0) {
      return notifications.map((item) => (
        <React.Fragment key={item.id}>
          <ListItem button onClick={() => openNotification(item)} alignItems="flex-start" display="flex">
            <ListItemAvatar>
              <Badge
                color="secondary"
                badgeContent=" "
                invisible={!!item.viewedAt}
                variant="dot"
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <Avatar>
                  <LocalShipping />
                </Avatar>
              </Badge>
            </ListItemAvatar>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
              <ListItemText
                primary={<Typography variant="body1" style={{ fontWeight: item.viewedAt ? 'normal' : 'bold' }}>{item.title}</Typography>}
                secondary={<Typography variant="body2" style={{ fontWeight: item.viewedAt ? 'normal' : 'bold' }}>{item.subtitle}</Typography>}
              />
              <Typography variant="caption" color="textSecondary">
                {item.createdAtFormatted}
              </Typography>
            </Grid>
            <IconButton onClick={(e) => { e.stopPropagation(); deleteNotification(item); }} className={classes.deleteButton}>
              <Close />
            </IconButton>
          </ListItem>
          <Divider component="li" />
        </React.Fragment>
      ));
    } else {
      return <ListSubheader className={classes.subheader}>Não há notificaçōes</ListSubheader>;
    }
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleClick} >
        <Badge badgeContent={totalUnread} color="secondary">
          <NotificationsIcon style={{ color: 'white' }} />
        </Badge>
      </IconButton>
      <Popper className={classes.popper} open={isNotificationOpen} anchorEl={anchorEl} placement='bottom-end' transition ref={popperRef}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper square className={classes.paper}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" gutterBottom className={classes.text}>
                  {totalUnread > 0 ? `${totalUnread > 99 ? "99+" : totalUnread} não lida${totalUnread > 1 ? 's' : ''}` : 'Não há novas notificações'}
                </Typography>
                <Typography variant="subtitle1" gutterBottom className={classes.text}>Notificações</Typography>
              </Grid>
              <List className={classes.list}>
                {listItemsNotification()}
                {notifications.length < total && (
                  <ListItem button onClick={nextPage} style={{ justifyContent: 'center' }}>
                    <ListItemText primary="Ver mais" style={{ textAlign: 'center' }} />
                  </ListItem>
                )}
              </List>
              <div className={classes.buttonContainer}>
                <Button className={classes.button} onClick={viewAllNotifications}>
                  Ler todas
                </Button>
                <Button className={classes.button} onClick={removeAllNotification}>
                  Excluir todas
                </Button>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

NotificationsNavbarComponent.propTypes = {
  notificationsRepository: PropTypes.instanceOf(NotificationsRepository),
  notifications: PropTypes.instanceOf(NotificationModel),
};
