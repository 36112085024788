/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tooltip, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  AccountBalance,
  CheckCircleOutline,
  Receipt,
  NewReleases,
  AccountTree,
  FormatListBulleted,
  PlaylistAddCheck,
  LocalShipping,
  OfflinePin,
  Error,
  ZoomOutMap
} from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import Swal from "sweetalert2";
import { PieChart } from 'react-minimal-pie-chart';
import { DeliveriesRepository } from "../../../shared/repositories";
import { authorizeChecker } from "../../../shared/helpers";
import { useSelector } from "react-redux";
import { SendSmsButton } from "../../../shared/components";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  gridCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },
  title: {
    color: "#377CC9"
  },
  ulList: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
  },
  gridList: {
    marginLeft: theme.spacing(5),
  },
  gridTitle: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "flex-start",
  },
  graphSkeleton: {
    [theme.breakpoints.down('sm')]: {
      width: "280px",
      height: "280px",
    },
    [theme.breakpoints.up('sm')]: {
      width: "150px",
      height: "150px",
    },
    [theme.breakpoints.up('md')]: {
      width: "200px",
      height: "200px",
    },
    [theme.breakpoints.up('lg')]: {
      width: "300px",
      height: "300px",
    },
  }
}));

export function DashboardChartComponent({ loading }) {
  const { auth } = useSelector(state => state);
  const classes = useStyles();
  const [statuses, setStatuses] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [total, setTotal] = useState(0);
  const deliveriesRepository = new DeliveriesRepository();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await deliveriesRepository.toBeDeliveredToday({ perPage: 1000 });
        setDisabled(response.data.filter(del => del.smsWillBeDeliveredTodayAt === null).length === 0);
        setTotal(response.total);

        setStatuses([
          { label: "open", title: 'Abertos', value: response.data.filter(del => del.status === "open" && !del.isPickup).length, color: '#3b3b3b', icon: <NewReleases /> },
          { label: "separation", title: 'Separação', value: response.data.filter(del => del.status === "separation" && !del.isPickup).length, color: '#5a00b3', icon: <AccountTree /> },
          { label: "checking", title: 'Conferência', value: response.data.filter(del => del.status === "checking" && !del.checked_at && !del.isPickup).length, color: '#c98437', icon: <FormatListBulleted /> },
          { label: "checked", title: 'Conferido', value: response.data.filter(del => del.status === "checking" && del.checked_at && !del.isPickup).length, color: '#8d5d26', icon: <PlaylistAddCheck /> },
          { label: "awaiting_billing", title: 'Aguardando Faturamento', value: response.data.filter(del => del.status === "awaiting_billing" && !del.isPickup).length, color: '#8d8d16', icon: <AccountBalance /> },
          { label: "billed", title: 'Faturado', value: response.data.filter(del => del.status === "billed" && !del.isPickup).length, color: '#42e142', icon: <Receipt /> },
          { label: "out_for_delivery", title: 'Saiu para Entrega', value: response.data.filter(del => del.status === "out_for_delivery" && !del.isPickup).length, color: '#37c984', icon: <LocalShipping /> },
          { label: "delivered", title: 'Entregue', value: response.data.filter(del => del.status === "delivered" && !del.isPickup).length, color: '#268d5d', icon: <OfflinePin /> },
          { label: "problem", title: 'Problema na Entrega', value: response.data.filter(del => del.status === "problem" && !del.isPickup).length, color: '#c93737', icon: <Error /> },
          { label: "retirada", title: 'Retirada', value: response.data.filter(del => del.isPickup).length, color: '#377CC9', icon: <ZoomOutMap /> },
        ]);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };

    fetchData();
  }, []);

  const sendSms = () => {
    Swal.fire({
      title: 'Confirmar?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Enviar mesmo assim',
      denyButtonText: 'Cancelar',
      html: `Ao enviar o SMS, todos os clientes das entregas de hoje serão notificados.`,
    }).then((result) => {
      if (result.isConfirmed) {
        deliveriesRepository.sendSmsWillBeDeliveredToday().then(() => { setDisabled(true); });
      }
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.gridTitle} style={{ justifyContent: 'flex-start', paddingTop: '25px' }}>
          {loading ? (
            <Skeleton variant="rect" width={'300px'} height={'30px'} />
          ) : (
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              <span>{total} Entregas para <b>Hoje</b></span>
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} className={classes.gridTitle} style={{ justifyContent: 'flex-end' }}>
          {authorizeChecker(auth) && (
            <Tooltip title="Enviar SMS para todos os clientes das entregas de hoje" placement="top">
              <SendSmsButton onClick={sendSms} disabled={disabled}>
                {disabled ? <>Enviado <CheckCircleOutline style={{ color: green[600] }} /></> : "Enviar SMS"}
              </SendSmsButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid container className={classes.gridCircle}>
          <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            {loading ? (
              <Skeleton variant="circle" className={classes.graphSkeleton} />
            ) : (
              <PieChart
                className={classes.graphSkeleton}
                data={statuses}
                totalValue={total}
                startAngle={-180}
                lineWidth={20}
                paddingAngle={1}
                labelPosition={50}
                lengthAngle={360}
                radius={50}
                viewBoxSize={[100, 100]}
              />
            )}
          </Grid>
          {loading ? (
            <Grid item xs={12} md={6}>
              <ul className={classes.ulList}>
                {[...Array(statuses.length)].map((_, index) => (
                  <li key={index} style={{ paddingTop: "5px", listStyleType: "none" }}>
                    <Skeleton variant="rect" width={'100px'} style={{ paddingLeft: "10px" }} />
                  </li>
                ))}
              </ul>
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <ul className={classes.ulList}>
                {statuses.filter(status => status.value > 0).map(dataEntry => (
                  <ul key={dataEntry.label} style={{ paddingTop: "10px", color: dataEntry.color, display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                    {dataEntry.icon}<span style={{ whiteSpace: 'nowrap' }}> - {dataEntry.title} {dataEntry.value}</span>
                  </ul>
                ))}
              </ul>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
