import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, MenuItem, List, ListItem } from "@material-ui/core";

import {
  TextInputFieldComponent,
  BackButton,
  SaveButton,
  PhoneList,
  EmailList,
  AddressList
} from "../../../shared/components";
import { Constants } from "../../../shared/helpers";
import { ClientsRepository } from "../../../shared/repositories";
import { ClientModel } from "../../../shared/models";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '500px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    }
  }
}));

export function ClientsDetailComponent({ update }) {
  const classes = useStyles();
  const [clientDetails, setClientDetails] = React.useState({});
  const clientRepository = new ClientsRepository();
  const { drawerValue, closeDrawerRight } = useDrawerRightContext();

  const saveClient = _ => {
    const clientModel = new ClientModel(clientDetails);
    clientRepository.update(clientModel.toJSON()).then(data => {
      const clientModel = new ClientModel(data);
      update(clientModel)
      setClientDetails(clientModel.toState())
    })
  }

  React.useEffect(() => {
    setClientDetails(drawerValue)
  }, [drawerValue])

  const handleChange = name => event => {
    setClientDetails({ ...clientDetails, [name]: event.target.value });
  };

  const handleChangePhones = (phones) => {
    handleChange('phones')({ target: { value: phones } })
  }

  const handleChangeEmails = (emails) => {
    handleChange('emails')({ target: { value: emails } })
  }

  const handleChangeAddresses = (address) => {
    handleChange('addresses')({ target: { value: address } })
  }

  const goBack = () => {
    closeDrawerRight()
  }

  return (
    <Grid className={classes.root}>
      <List>
        <ListItem>
          <Typography variant="h6" gutterBottom>Dados do cliente</Typography>
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="firstName-select"
            label="Primeiro nome"
            value={clientDetails.firstName}
            onChange={handleChange('firstName')}
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="lastName-select"
            label="Sobrenome"
            value={clientDetails.lastName}
            onChange={handleChange('lastName')}
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="document-select"
            label="Documento"
            value={clientDetails.document}
            onChange={handleChange('document')}
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="status-select"
            select
            label="Status"
            value={clientDetails.status}
            onChange={handleChange("status")}>
            {Constants.Client.Statuses.filter(status => status.label !== "all").map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {option.value}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </ListItem>
        <ListItem style={{ marginRight: theme => theme.spacing(2) }}>
          <PhoneList phones={clientDetails.phones} handleChange={handleChangePhones} />
        </ListItem>
        <ListItem>
          <EmailList emails={clientDetails.emails} handleChange={handleChangeEmails} />
        </ListItem>
        <ListItem>
          <AddressList addresses={clientDetails.addresses} handleChange={handleChangeAddresses} />
        </ListItem>
        <ListItem style={{ display: "flex", justifyContent: "space-between" }}>
          <BackButton onClick={goBack} />
          <SaveButton onClick={saveClient} />
        </ListItem >
      </List >
    </Grid >
  );
}

