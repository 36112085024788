import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Paper, Grid, Typography, Tooltip, Switch, FormControlLabel } from "@material-ui/core";

import {
  TextInputFieldComponent,
  BetweenDateInputFieldComponent,
  ClearButton,
  CreatePdfButton,
  CreateXlsxButton,
  SearchButton
} from "../../../shared/components";
import { authorizeChecker, Constants } from "../../../shared/helpers";
import { DeliveriesRepository } from "../../../shared/repositories";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight: 380,
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  gridItemDate: {
    paddingRight: theme.spacing(3),
  },
  gridItemButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }
}));

export function DeliveriesSearchComponent({ setFilters, users, stores, regions, INITIAL_FILTER, setLoading, resolveFilters = () => { } }) {
  const classes = useStyles();
  const auth = useSelector(state => state.auth);
  const [localFilters, setLocalFilters] = React.useState(INITIAL_FILTER);
  const [drivers, setDrivers] = useState([]);
  const [chiefDrivers, setChiefDrivers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const deliveriesRepository = new DeliveriesRepository();

  const searchDeliveries = () => {
    setFilters(localFilters);
  };

  useEffect(() => {
    setDrivers(users.filter(user => user.role === Constants.User.Role.Driver));
    setChiefDrivers(users.filter(user => user.role === Constants.User.Role.ChiefDriver));
    setSellers(users.filter(user => user.role === Constants.User.Role.Seller));
  }, [users]);

  const clearFilters = () => {
    setLocalFilters(INITIAL_FILTER);
    setFilters(INITIAL_FILTER);
  }

  const handleChange = name => event => {
    setLocalFilters({ ...localFilters, [name]: event.target.value });
  };

  const handleChangeDate = name => date => {
    setLocalFilters({ ...localFilters, [name]: date ? date._d : null });
  };

  const handleSubmitPdf = () => {
    let resolvedFilters = resolveFilters();
    if (resolvedFilters.length < 1)
      resolvedFilters = `expected_delivery_in=${new Date().toLocaleDateString('pt-BR')}`;
    return deliveriesRepository.getAll({ queryParams: resolvedFilters, type: 'pdf', perPage: 100000 })
  }

  const handleSubmitXlsx = () => {
    let resolvedFilters = resolveFilters();
    if (resolvedFilters.length < 1)
      resolvedFilters = `expected_delivery_in=${new Date().toLocaleDateString('pt-BR')}`;
    return deliveriesRepository.getAll({ queryParams: resolvedFilters, type: 'xlsx', perPage: 100000 })
  }

  return (
    <Paper className={classes.paper}>
      <Grid container >
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>Filtrar Entregas</Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem} style={{ display: "flex", justifyContent: "right" }}>
          <FormControlLabel
            style={{ paddingLeft: "10px" }}
            control={
              <Switch
                className={classes.switch}
                color="primary"
                size="small"
                checked={localFilters.isPickup}
                onChange={() => handleChange("isPickup")({ target: { value: !localFilters.isPickup } })}
              />
            }
            label="Retirada?"
          />
          <FormControlLabel
            style={{ paddingLeft: "10px" }}
            control={
              <Switch
                className={classes.switch}
                color="primary"
                size="small"
                checked={localFilters.coating}
                onChange={() => handleChange("coating")({ target: { value: !localFilters.coating } })}
              />
            }
            label="Revestimento? (M2)"
          />
        </Grid>
      </Grid>
      <Grid container >
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextInputFieldComponent
            id="legacyOrderId"
            variant="outlined"
            label="Pedido"
            value={localFilters.legacyOrderId}
            onChange={handleChange("legacyOrderId")}
            onKeyPress={e => { if (e.key === 'Enter') searchDeliveries(); }}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextInputFieldComponent
            id="standard-select-status"
            variant="outlined"
            select
            label="Status"
            value={localFilters.status}
            onChange={handleChange("status")}>
            {Constants.Delivery.Statuses.map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {option.value}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextInputFieldComponent
            id="parent-id"
            variant="outlined"
            select
            label="Motorista Chefe"
            value={localFilters.parentId}
            onChange={handleChange("parentId")}>
            <MenuItem key={"all"} value={"all"}>Todos</MenuItem>
            {chiefDrivers.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextInputFieldComponent
            id="driver-id"
            variant="outlined"
            select
            label="Motorista"
            value={localFilters.driverId}
            onChange={handleChange("driverId")}>
            <MenuItem key={"all"} value={"all"}>Todos</MenuItem>
            {drivers.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextInputFieldComponent
            id="store-id"
            variant="outlined"
            select
            label="Loja"
            value={localFilters.storeId}
            onChange={handleChange("storeId")}>
            <MenuItem key={"all"} value={"all"}>Todos</MenuItem>
            {stores.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextInputFieldComponent
            id="seller-id"
            variant="outlined"
            select
            label="Vendedor"
            value={localFilters.sellerId}
            onChange={handleChange("sellerId")}>
            <MenuItem key={"all"} value={"all"}>Todos</MenuItem>
            {sellers.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <TextInputFieldComponent
              id="region-id"
              variant="outlined"
              select
              label="Regiões"
              value={localFilters.regionId}
              onChange={handleChange("regionId")}>
              <MenuItem key={"all"} value={"all"}>Todos</MenuItem>
              {regions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextInputFieldComponent>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItemDate}>
          <BetweenDateInputFieldComponent
            id="expected-delivery-in"
            label="Data da Entrega Prevista"
            startDate={localFilters.expectedDeliveryInStart}
            endDate={localFilters.expectedDeliveryInEnd}
            disabledDate={localFilters.expectedDeliveryInFuture}
            onDisabledChange={handleChange("expectedDeliveryInFuture")}
            disabledTooltipText="Entrega Futura"
            onChangeStart={handleChangeDate("expectedDeliveryInStart")}
            onChangeEnd={handleChangeDate("expectedDeliveryInEnd")}
            onKeyPress={e => { if (e.key === 'Enter') searchDeliveries(); }}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItemDate}>
          <BetweenDateInputFieldComponent
            id="delivery-at"
            label="Entregue em"
            startDate={localFilters.deliveredAtStart}
            endDate={localFilters.deliveredAtEnd}
            onChangeStart={handleChangeDate("deliveredAtStart")}
            onChangeEnd={handleChangeDate("deliveredAtEnd")}
            onKeyPress={e => { if (e.key === 'Enter') searchDeliveries(); }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gridItemButton} align="center">
        <SearchButton onClick={searchDeliveries}>Filtrar</SearchButton>
        <CreatePdfButton onClick={handleSubmitPdf} >PDF</CreatePdfButton>
        {authorizeChecker(auth) && <CreateXlsxButton onClick={handleSubmitXlsx} >Excel</CreateXlsxButton>}
        <ClearButton onClick={clearFilters} >Limpar</ClearButton>
      </Grid>
    </Paper >
  )
}
