/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Grid,
  Card,
  CardContent,
  CardActions,
  Tooltip,
} from "@material-ui/core";
import { Block, CheckRounded, PriorityHighRounded } from "@material-ui/icons";
import { blue, green, grey, red, yellow } from "@material-ui/core/colors";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import Swal from "sweetalert2";


import { ClearButton } from "../../../shared/components";
import { RestrictedDatesRepository } from "../../../shared/repositories";
import { Constants, authorizeChecker, t } from "../../../shared/helpers";

const useStyles = makeStyles(theme => ({
  title: {
    color: "#377CC9"
  },
  container: {
    flexWrap: 'nowrap',
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight: 380,
  },
  tableRowStyle: {
  },
  tableCellSmall: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: "0px"
    }
  },
  calendarSpanStyle: {
    borderRadius: '16px',
    padding: '3px 3px 3px 3px'
  },
  calendarSpanIconStyle: {
    position: 'absolute',
    top: '30px',
    [theme.breakpoints.down('sm')]: {
      right: '10%',
    },
    [theme.breakpoints.up('sm')]: {
      right: '20%',
    },
    [theme.breakpoints.up('lg')]: {
      right: '15%',
    },
    [theme.breakpoints.up('xl')]: {
      right: '30%',
    },
  },
  dateTypographyStyle: {
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem'
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '0.7rem'
    },
  },
  blockDateButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.5rem'
    },
  },
  cardStyle: {
    height: '180px',
    width: "100%"
  },
  cardContentStyle: {
    height: '137px'
  }
}));

const today = new Date()

export function DashboardNextDaysComponent(props) {
  const { loading } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { auth, calendars } = useSelector(state => state);
  const [selectedDate, setSelectedDate] = useState({})
  const restrictedDatesRepository = new RestrictedDatesRepository();

  useEffect(() => {
    if (!selectedDate.date) {
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + (tomorrow.getDay() === 6 ? 2 : 1))
      const date = calendars.filter(cal => cal.date === tomorrow.toLocaleDateString())[0]
      if (date)
        setSelectedDate(date)
    }
  }, [calendars])

  const blockDate = () => {
    Swal.fire({
      title: `Bloquear data ${selectedDate.date}`,
      showDenyButton: true,
      showCancelButton: true,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      confirmButtonText: 'Bloquear',
      denyButtonText: 'Cancelar',
      html: `Qual o motivo para bloquear essa data?.`,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value.length < 3) {
            resolve('Motivo deve ter pelo menos 3 caracteres')
          } else {
            resolve()
          }
        })
      }
    }).then((result) => {
      if (result.isConfirmed) {
        restrictedDatesRepository.create({
          reason: result.value,
          start_date: selectedDate.date
        })
          .then(result => {
            setSelectedDate({ ...selectedDate, reason: result.reason, status: Constants.Calendar.Status.Blocked })
            dispatch({
              type: "UPDATE_CALENDARS_ITEM", value: {
                date: new Date(`${result.start_date} `).toLocaleDateString(),
                reason: result.reason,
                status: Constants.Calendar.Status.Blocked
              }
            })
          })
      }
    })
  }

  const resolveStatusIcon = (day) => {
    switch (day.status) {
      case Constants.Calendar.Status.Blocked:
        return <Block style={{ fontSize: 'medium', color: red[600] }} />
      case Constants.Calendar.Status.Warning:
        return <PriorityHighRounded style={{ fontSize: 'medium', color: yellow[800] }} />
      default:
        if (day.date)
          return <CheckRounded style={{ fontSize: 'medium', color: green[600] }} />
    }
  }

  const newDate = (date) => {
    if (date) {
      const [day, month, year] = date.split("/")
      return new Date(year, month - 1, day)
    }
  }

  const Calendar = () => {

    if (loading)
      return [...Array(6)].map((_, index) => (
        <TableRow key={`row-${index}`} className={classes.tableRowStyle}>
          {[...Array(7)].map((_, i) =>
            <TableCell key={i} variant='body' align="center" className={classes.tableCellSmall}>
              <Skeleton variant="text" />
            </TableCell>
          )}
        </TableRow>
      ))

    const resolveColor = (day) =>
      selectedDate.date && selectedDate.date === day.date ? blue[600] :
        today.toLocaleDateString() === day.date ? blue[200] :
          day.week_day === 'Sunday' ? grey[400] :
            ''

    const resolveTextColor = (day) =>
      (selectedDate.date && selectedDate.date === day.date) ? 'white' :
        day.week_day === 'Sunday' || today.toLocaleDateString() === day.date ? grey[800] :
          'black'

    const loop = (times) => {
      let arr = []
      for (let i = 0; i < times; i++) {
        arr = arr.concat({});
      }
      return arr
    };

    const chunks = (a, size) =>
      Array.from(
        new Array(Math.ceil(a.length / size)),
        (_, i) => a.slice(i * size, i * size + size)
      );

    const getWeekDay = (date) => {
      if (date) {
        const [day, month, year] = date.split("/")
        return moment({ year, month: month - 1, day }).weekday()
      }
    }

    if (calendars[0] && calendars[0].date) {
      let calendar = calendars;
      let firstWeekday = getWeekDay(calendars[0].date);
      let lastWeekday = 6 - getWeekDay(calendars.at(-1).date);

      calendar = loop(firstWeekday).concat(calendar)
      calendar = calendar.concat(loop(lastWeekday))
      calendar = chunks(calendar, 7)

      return calendar.map((days, index) => (
        <TableRow key={`row-${index}`} className={classes.tableRowStyle}>
          {days.map((day, i) =>
            <TableCell key={`cell-${i}`} variant='body' align="center" className={classes.tableCellSmall}>
              <Tooltip title={day.date ? day.amount ? `${day.amount ? `${day.amount} ${day.amount > 1 ? "entregas" : "entrega"}` : ''} - ${day.weight ? `${day.weight} kg` : ''}` : 'Não há entregas' : ''}>
                <Typography
                  variant="body2"
                  onClick={(e) => day.week_day !== 'Sunday' && day.date && setSelectedDate(day)}
                  className={classes.dateTypographyStyle}
                  style={{
                    cursor: day.week_day === 'Sunday' ? 'not-allowed' : day.date ? 'pointer' : 'unset'
                  }}
                >
                  <span
                    className={classes.calendarSpanStyle}
                    style={{
                      backgroundColor: resolveColor(day),
                      color: resolveTextColor(day)
                    }}>
                    {day.date ? day.date.split("/")[0] : ''}
                  </span>
                  <span
                    className={classes.calendarSpanIconStyle}
                  >
                    {day.week_day !== "Sunday" && resolveStatusIcon(day)}
                  </span>
                </Typography>
              </Tooltip>
            </TableCell>
          )}
        </TableRow >
      ))
    }
    return <></>
  }

  return <Grid container justifyContent="center" alignContent="center">
    <Grid container xs={12} flexDirection='row'>
      <Grid item xs={12}>
        <Table style={{ height: '343px' }}>
          <TableHead>
            <TableRow>
              <TableCell variant='head' padding='none' align="center">D</TableCell>
              <TableCell variant='head' padding='none' align="center">S</TableCell>
              <TableCell variant='head' padding='none' align="center">T</TableCell>
              <TableCell variant='head' padding='none' align="center">Q</TableCell>
              <TableCell variant='head' padding='none' align="center">Q</TableCell>
              <TableCell variant='head' padding='none' align="center">S</TableCell>
              <TableCell variant='head' padding='none' align="center">S</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Calendar />
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.cardStyle}>
          <CardContent className={classes.cardContentStyle}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {selectedDate.date} <b>{t(`calendars.statuses.${selectedDate.status}`)}</b> {resolveStatusIcon(selectedDate)}
            </Typography>
            <Typography variant="h5" component="h2">
              {selectedDate.amount ? `${selectedDate.amount} ${selectedDate.amount > 1 ? "entregas" : "entrega"}` : '0 entregas'}
            </Typography>
            <Typography variant="h6" component="h2">
              {selectedDate.to_pickup ? `${selectedDate.to_pickup} ${selectedDate.to_pickup > 1 ? "retiradas" : "retirada"}` : '0 retiradas'}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {selectedDate.weight ? `${selectedDate.weight} kg` : '0 kg'}
            </Typography>
            <Typography variant="body2" component="p">
              Motivo: {selectedDate.reason ? selectedDate.reason : 'Não há'}
            </Typography>
            <Typography variant="body2" component="p">
              {selectedDate.not_deliveried ? `${selectedDate.not_deliveried} ${selectedDate.not_deliveried > 1 ? "não entregues" : "não entregue"}` : '0 não entregue'}
            </Typography>
          </CardContent>
          <CardActions style={{ display: "flex", justifyContent: "flex-end", padding: '0px' }}>
            {authorizeChecker(auth) && <ClearButton
              size="small"
              disabled={selectedDate.status === Constants.Calendar.Status.Blocked || (selectedDate.date && newDate(selectedDate.date) < today)}
              onClick={blockDate}
              className={classes.blockDateButton}
            >
              {newDate(selectedDate.date) < today ? "Data já passou" : "Bloquear data"}
            </ClearButton>
            }
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  </Grid>
}
