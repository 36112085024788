import React from "react";
import { CircularProgress, Tooltip } from "@material-ui/core";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { red } from "@material-ui/core/colors";
import { IconButton } from "./";

export function DeleteIconButton({ onClick = () => { }, disabled = false, className, ...props }) {
  const [status, setStatus] = React.useState('');

  const handleClick = e => {
    e.preventDefault();
    try {
      setStatus('loading');
      onClick().finally(_ => setStatus('success'))
    }
    catch (error) {
      setStatus('error');
    }
  }

  return (
    <IconButton
      className={className}
      {...props}
      color={red[500]}
      hoverColor={red[700]}
      hoverBgColor={'white'}
      disabled={disabled || status === 'loading'}
      onClick={handleClick}
      show={true}
    >
      <Tooltip title="Deletar">{status === 'loading' ? <CircularProgress size={24} /> : <DeleteForever />}</Tooltip>
    </IconButton>
  );
}
