import React, { useEffect } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';

const DrawerRightContext = createContext({
	isDrawerRightOpen: false,
	drawerValue: {},

	setNewDrawerValue: () => { },
	closeDrawerRight: () => { },
});

export const useDrawerRightContext = () => {
	return useContext(DrawerRightContext);
};

export const DrawerRightProvider = ({ children }) => {
	const [isDrawerRightOpen, setIsDrawerRightOpen] = useState(false);
	const [drawerValue, setDrawerValue] = useState();
	const [forceOpen, setForceOpen] = useState(false);

	const closeDrawerRight = useCallback(() => {
		if (forceOpen) {
			setForceOpen(false);
			return;
		}

		setIsDrawerRightOpen(false);
		setDrawerValue();
	}, [forceOpen]);

	const setNewDrawerValue = (value, open) => {
		setForceOpen(open);
		setIsDrawerRightOpen(true);
		setDrawerValue(value);
	};

	return (
		<DrawerRightContext.Provider value={{
			isDrawerRightOpen,
			drawerValue,

			setNewDrawerValue,
			closeDrawerRight
		}}>
			{children}
		</DrawerRightContext.Provider>
	);
};
