import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, List, ListItem } from "@material-ui/core";

import {
  NumberPhoneInputFieldComponent,
  TextInputFieldComponent,
  BackButton,
  SaveButton
} from "../../../shared/components";
import { StoresRepository } from "../../../shared/repositories";
import { StoreModel } from "../../../shared/models";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    }
  }
}));

export function StoresDetailComponent({ update }) {
  const classes = useStyles();
  const [store, setStore] = React.useState({});
  const storeRepository = new StoresRepository();
  const { drawerValue, closeDrawerRight } = useDrawerRightContext();

  const saveStore = _ => {
    const storeToSave = new StoreModel(store);
    return storeRepository[storeToSave.id ? 'update' : 'create'](storeToSave.toJSON())
      .then(store => {
        update(store)
        setStore(store.toState())
      });
  }

  React.useEffect(() => {
    setStore(drawerValue)
  }, [drawerValue])

  const handleChange = name => event => {
    setStore({ ...store, [name]: event.target.value });
  };

  const goBack = () => {
    closeDrawerRight()
  }

  return (
    <Grid className={classes.root}>
      <List>
        <ListItem>
          <Typography variant="h6" gutterBottom>Dados da Loja</Typography>
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="name"
            label="Nome"
            value={store.name}
            onChange={handleChange('name')}
          />
        </ListItem >
        <ListItem>
          <NumberPhoneInputFieldComponent
            value={store.phoneNumber}
            onChange={handleChange('phoneNumber')}
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="emailAddress"
            label="Email"
            value={store.emailAddress}
            onChange={handleChange('emailAddress')}
          />
        </ListItem >
        <ListItem>
          <TextInputFieldComponent
            id="document-select"
            label="Documento"
            value={store.document}
            onChange={handleChange('document')}
          />
        </ListItem >
        <ListItem style={{ display: "flex", justifyContent: "space-between" }}>
          <BackButton onClick={goBack} />
          <SaveButton onClick={saveStore} />
        </ListItem >
      </List >
    </Grid >
  );

}

