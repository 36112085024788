export const Constants = {
  development: {
    apiUrl: "http://localhost:3001/",
    wsUrl: "ws://localhost:3001/"
  },
  production: {
    apiUrl: "https://api.track.trykat.com/",
    wsUrl: "wss://api.track.trykat.com/"
  },
  settings: {
    permittedLanguages: ["pt-br", "en-us"],
    defaultLanguage: "pt-br"
  },
  SnackBar: {
    Success: {
      autoHideDuration: 2000,
      variant: "success"
    },
    Error: {
      autoHideDuration: 10000,
      variant: "error",
      message: "Erro para completar ação, caso seja recorrente entrar em contato com o administrador do sistema"
    },
    Warning: {
      autoHideDuration: 5000,
      variant: "warning"
    },
    Info: {
      autoHideDuration: 5000,
      variant: "info"
    },
  },
  Calendar: {
    Status: {
      Available: "available",
      Blocked: "blocked",
      Warning: "warning"
    },
  },
  Client: {
    Status: {
      Active: "active",
      Inactive: "inactive"
    },
    Statuses: [
      { label: "all", value: "Todos" },
      { label: "active", value: "Ativo" },
      { label: "inactive", value: "Inativo" }
    ]
  },
  User: {
    minPasswordLength: 8,
    Role: {
      Owner: "owner",
      Admin: "admin",
      Driver: "driver",
      Checker: "checker",
      ChiefDriver: "chief_driver",
      Seller: "seller",
      Separator: "separator",
      System: "system"
    },
    Roles: [
      { label: "all", value: "Todos" },
      { label: "system", value: "Sistema" },
      { label: "owner", value: "Proprietário" },
      { label: "admin", value: "Administrador" },
      { label: "driver", value: "Motorista" },
      { label: "checker", value: "Conferente" },
      { label: "chief_driver", value: "Motorista Chefe" },
      { label: "seller", value: "Vendedor" },
      { label: "separator", value: "Separador" }
    ],
    Status: {
      Active: "active",
      Inactive: "inactive",
      Deleted: "deleted"
    },
    Statuses: [
      { label: "all", value: "Todos" },
      { label: "active", value: "Ativo" },
      { label: "inactive", value: "Inativo" },
      { label: "deleted", value: "Deletado" }
    ]
  },
  Pickup: {
    Status: {
      Open: "open",
      Separation: "separation",
      Checking: "checking",
      AwaitingBilling: "awaiting_billing",
      Billed: "billed",
      OutForDelivery: "out_for_delivery",
      Delivered: "delivered",
      Problem: "problem",
      Cancelled: "cancelled"
    },
    Statuses: [
      { label: "all", value: "Todos" },
      { label: "open", value: "Aberto" },
      { label: "separation", value: "Separação" },
      { label: "checking", value: "Conferência" },
      { label: "checked", value: "Conferido" },
      { label: "awaiting_billing", value: "Aguardando faturamento" },
      { label: "billed", value: "Faturado" },
      { label: "out_for_delivery", value: "Aguardando retirada" },
      { label: "delivered", value: "Retirado" },
      { label: "problem", value: "Problema na retirada" },
      { label: "cancelled", value: "Cancelado" }
    ],
  },
  Delivery: {
    FreightType: {
      Automatic: "automatic",
      Manual: "manual",
    },
    FreightTypes: [
      { label: "all", value: "Todos" },
      { label: "automatic", value: "Automático" },
      { label: "manual", value: "Manual" },
    ],
    Type:{
      Delivery: "delivery",
      Pickup: "pickup"
    },
    Types: [
      { label: "Delivery", value: "Entrega" },
      { label: "Pickup", value: "Retirada" }
    ],
    Status: {
      Open: "open",
      Separation: "separation",
      Checking: "checking",
      AwaitingBilling: "awaiting_billing",
      Billed: "billed",
      OutForDelivery: "out_for_delivery",
      Delivered: "delivered",
      Problem: "problem",
      Cancelled: "cancelled"
    },
    Statuses: [
      { label: "all", value: "Todos" },
      { label: "open", value: "Aberto" },
      { label: "separation", value: "Separação" },
      { label: "checking", value: "Conferência" },
      { label: "checked", value: "Conferido" },
      { label: "awaiting_billing", value: "Aguardando faturamento" },
      { label: "billed", value: "Faturado" },
      { label: "out_for_delivery", value: "Saiu para entrega" },
      { label: "delivered", value: "Entregue" },
      { label: "problem", value: "Problema na entrega" },
      { label: "cancelled", value: "Cancelado" }
    ],
    PickupStatuses: [
      { label: "all", value: "Todos" },
      { label: "open", value: "Aberto" },
      { label: "separation", value: "Separação" },
      { label: "checking", value: "Conferência" },
      { label: "checked", value: "Conferido" },
      { label: "awaiting_billing", value: "Aguardando faturamento" },
      { label: "billed", value: "Faturado" },
      { label: "out_for_delivery", value: "Aguardando retirada" },
      { label: "delivered", value: "Retirado" },
      { label: "problem", value: "Problema na retirada" },
      { label: "cancelled", value: "Cancelado" }
    ],
    DeliveryReason: {
      OrderSplit: "order_split",
      GoodsMissing: "goods_missing",
      DamageRedelivery: "damage_redelivery",
      OperationalDcProblem: "operational_dc_problem",
      OperationalStoreProblem: "operational_store_problem",
      Other: "other"
    },
    DeliveryReasons: [
      { label: "all", value: "Todos" },
      { label: "order_split", value: "Divisão do pedido" },
      { label: "goods_missing", value: "Falta de mercadoria" },
      { label: "damage_redelivery", value: "Reentrega por avaria" },
      { label: "operational_dc_problem", value: "Reentrega por erro operacional cd" },
      { label: "operational_store_problem", value: "Reentrega por erro operacional loja" },
      { label: "other", value: "Outro" },
    ]
  },
  Separation: {
    Reason: {
      lunchBreak: "Parada para Almoço",
      missingProduct: "Produto em falta",
      loadDivision: "Divisão de carga",
      other: "Outro",
    },
    Reasons: [
      { label: "all", value: "Todos" },
      { label: "lunch_break", value: "Parada para Almoço" },
      { label: "missing_product", value: "Produto em falta" },
      { label: "load_division", value: "Divisão de carga" },
      { label: "other", value: "Outro" },
    ],
  },
  RestrictedDate: {
    Status: {
      Active: "active",
      Inactive: "inactive",
      Deleted: "deleted",
    },
    Statuses: [
      { label: "all", value: "Todos" },
      { label: "active", value: "Ativo" },
      { label: "inactive", value: "Inativo" },
      { label: "deleted", value: "Deletado" },
    ]
  },
  Vehicle: {
    Status: {
      Available: "available",
      Unavailable: "unavailable"
    },
    Statuses: [
      { label: "available", value: "Disponível" },
      { label: "unavailable", value: "Indisponível" }
    ],
    Category: {
      Car: "car",
      Truck: "truck",
      Motorcycle: "motorcycle"
    },
    Categories: [
      { label: "car", value: "Carro" },
      { label: "truck", value: "Caminhão" },
      { label: "motorcycle", value: "Moto" }
    ],
    Regions: [
      { label: "centro_sp", value: "Centro São Paulo", range: ["01000000 to 01599000"] },
      { label: "norte_sp", value: "Zona Norte São Paulo", range: ["02000000 to 02999000"] },
      { label: "lest_sp", value: "Zona Leste São Paulo", range: ["03000000 to 03999000", "08000000 to 08499000"] },
      { label: "sul_sp", value: "Zona Sul São Paulo", range: ["04000000 to 04999000"] },
      { label: "oeste_sp", value: "Zona Oeste São Paulo", range: ["05000000 to 05899000"] },
      { label: "grande_sp", value: "Grande São Paulo", range: ["06000000 to 07999000", "08500000 to 09999000"] },
      { label: "litoral_sp", value: "São Paulo Litoral", range: ["11000000 to 11999000"] },
      { label: "interior_sp", value: "São Paulo Interior", range: ["12000000 to 19999000"] },
      { label: "sao_jose_dos_campos", value: "São José dos Campos", range: ["12200000 to 12249000"] },
      { label: "campinas", value: "Campinas", range: ["13000000 to 13139000"] },
      { label: "riberao_preto", value: "Ribeirão Preto", range: ["14000000 to 14114000"] },
    ],
    Colors: [
      { label: "white", value: "Branco" },
      { label: "black", value: "Preto" },
      { label: "red", value: "Vermelho" },
      { label: "blue", value: "Azul" },
      { label: "orange", value: "Laranja" },
    ],
    Brands: [
      { label: "kia", value: "Kia" },
      { label: "iveco", value: "Iveco" },
      { label: "fiat", value: "Fiat" },
      { label: "honda", value: "Honda" }
    ],
    Models: [
      { label: "bongo", value: "Bongo", brand: "kia" },
      { label: "daily", value: "Daily", brand: "iveco" },
      { label: "strada", value: "Strada", brand: "fiat" },
      { label: "toro", value: "Toro", brand: "fiat" },
      { label: "cg 125", value: "CG 125", brand: "honda" },
      { label: "cg 150", value: "CG 150", brand: "honda" }
    ],
  },
}
