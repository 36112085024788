import React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";

import {
  AddIconButton,
  DeleteIconButton,
  EditIconButton,
  PasswordIconButton,
  TablePaginationComponent,
  TableRowLoadingComponent
} from "../../../shared/components";
import { UsersRepository } from "../../../shared/repositories";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";
import { authorizeChecker, removeSpecialCharsLowerCase } from "../../../shared/helpers";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const useStyles = makeStyles(theme => ({
  tableCell: {
    whiteSpace: "nowrap",
    fontSize: "13px",
  },
  tableCellHead: {
    whiteSpace: "nowrap",
    fontSize: "13px",
    fontWeight: "bold",
  },
  tableBody: {
    overflowX: "auto",
    maxHeight: 500
  }
}));

export function UsersListComponent({ loading, users = [], filters = {}, setFilters = () => { }, setUsers = () => { }, setLoading = () => { } }) {
  const auth = useSelector(state => state.auth);
  const usersRepository = new UsersRepository();
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 10, total: 0 });
  const classes = useStyles();
  const { setNewDrawerValue } = useDrawerRightContext();

  const viewMore = user => e => {
    e.preventDefault();
    setNewDrawerValue(user.toState())
  }

  const updatePassword = user => e => {
    e.preventDefault();
    Swal.fire({
      title: `Alterar senha de ${user.name}`,
      html: `<input id="password" class="swal2-input" type="password" placeholder="Nova senha" autocomplete="off" required>
             <input id="password_confirmation" class="swal2-input" type="password" placeholder="Confirmação de senha" autocomplete="off" required>`,
      focusConfirm: false,
      preConfirm: () => {
        return {
          password: document.getElementById('password').value,
          password_confirmation: document.getElementById('password_confirmation').value
        }
      }
    }).then(result => {
      if (result.isConfirmed) {
        usersRepository.updatePassword(user.id, result.value)
          .then(() => {
            Swal.fire('Sucesso', 'Senha alterada com sucesso', 'success')
          })
          .catch(err => {
            Swal.fire('Erro', err.message, 'error')
          })
      }
    })
  }

  const deleteRow = user => e => {
    // e.preventDefault();
    Swal.fire({
      title: `Deletar ${user.name}`,
      text: "Você não poderá reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6'
    }).then(result => {
      if (result.isConfirmed) {
        usersRepository.delete(user.id)
          .then(() => {
            let newUsers = users.map(item => {
              if (item.id === user.id) {
                item.status = "deleted"
              }
              return item
            });

            setUsers(newUsers);
            Swal.fire('Sucesso', 'Usuário deletado com sucesso', 'success')
          })
          .catch(err => {
            Swal.fire('Erro', err.message, 'error')
          })
      }
    })
  }

  const newItem = e => {
    e.preventDefault();
    setNewDrawerValue({})
  }

  React.useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      getUsers()
    }, 1000);
    return () => clearTimeout(timer);
  }, [pagination.page, pagination.perPage, filters]);

  const getUsers = () => {
    usersRepository.getAll({
      queryParams: resolveFilters(),
      page: pagination.page,
      perPage: pagination.perPage,
      orderBy: filters.orderBy,
      orderDirection: filters.orderDirection
    })
      .then(result => {
        window.data = result
        setUsers(result.data)
        setPagination({ ...pagination, total: result.total })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  const resolveFilters = () => {
    let query = [];

    if (filters.name) {
      query.push(`name=${removeSpecialCharsLowerCase(filters.name)}`)
    }

    if (filters.phoneNumber) {
      query.push(`phoneNumber=${removeSpecialCharsLowerCase(filters.phoneNumber)}`)
    }

    if (filters.role && filters.role !== "all") {
      query.push(`roles=${filters.role}`)
    }

    if (filters.status && filters.status !== "all") {
      query.push(`statuses=${filters.status}`)
    }

    return query.join("|");
  }

  const orderBy = (orderBy) => e => {
    setFilters({ ...filters, orderBy: orderBy, orderDirection: filters.orderDirection === 'asc' ? 'desc' : 'asc' })
  }

  const SortIcon = ({ orderBy }) => {
    if (filters.orderBy === orderBy) {
      if (filters.orderDirection === 'asc') {
        return <FontAwesomeIcon icon={faSortAsc} />
      } else {
        return <FontAwesomeIcon icon={faSortDesc} />
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={1}><AddIconButton onClick={newItem} disabled={!authorizeChecker(auth)} /></TableCell>
            <TableCell align="right" colSpan={5}>{users.length} itens</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center" onClick={orderBy('name')} className={classes.noWrap}>Nome <SortIcon orderBy="name" /></TableCell>
            <TableCell align="center" onClick={orderBy('username')} className={classes.noWrap}>Usuário <SortIcon orderBy="username" /></TableCell>
            <TableCell align="center">Telefone</TableCell>
            <TableCell align="center" onClick={orderBy('role')} className={classes.noWrap}>Perfil <SortIcon orderBy="role" /></TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {loading ?
            <TableRowLoadingComponent cols={6} buttons={3} /> :
            users.map(row => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell} >
                  <DeleteIconButton onClick={deleteRow(row)} disabled={!authorizeChecker(auth)} />
                  <EditIconButton onClick={viewMore(row)} />
                  <PasswordIconButton onClick={updatePassword(row)} disabled={!authorizeChecker(auth)} />
                </TableCell>
                <TableCell align="center" className={classes.noWrap}><b>{row.name}</b></TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.username}</TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.phoneNumberFormatted}</TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.roleName}</TableCell>
                <TableCell align="center" className={classes.noWrap}>{row.statusName}</TableCell>
              </TableRow>
            ))}
          {!loading && !users[0] ? <TableRow key="none" className={classes.tableRow}>
            <TableCell component="th" scope="row" colSpan="6" align="center" style={{ height: "300px" }}>
              <b>Não há usuários</b>
            </TableCell>
          </TableRow> : null}
        </TableBody>
        <TablePaginationComponent
          colSpan={6}
          count={pagination.total}
          rowsPerPage={pagination.perPage}
          page={pagination.page}
          loading={loading}
          onPageChange={value => { setPagination({ ...pagination, page: value }) }}
          onRowsPerPageChange={value => { setPagination({ ...pagination, page: 1, perPage: value }) }}
        />
      </Table>
    </TableContainer >)
}
