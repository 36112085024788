import Entity from "./entity.model";

const SUPPORTED_ATTRS = [
  "id",
  "title",
  "subtitle",
  "targetId",
  "targetType",
  "viewedAt",
  "user",
  "createdAt",
  "updatedAt",
];

export class NotificationModel extends Entity {
  constructor(attrs = {}) {
    super(attrs, SUPPORTED_ATTRS);
  }

  get createdAtFormatted() {
    return this.createdAt ? new Date(`${this.createdAt}`).toLocaleString() : null;
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      subtitle: this.subtitle,
      target_id: this.targetId,
      target_type: this.targetType,
      viewed_at: this.viewedAt
    };
  }
}
