import React from 'react';
import { Route } from "react-router-dom";
import { paths } from './paths.routes';
import {
  ClientsPage,
  CompaniesPage,
  DashboardPage,
  DeliveriesPage,
  MePage,
  MePasswordPage,
  ReportsPage,
  RegionsPage,
  StoresPage,
  RestrictedDatesPage,
  UsersPage,
  VehiclesPage,
  SeparationsPage,
  SectorsPage,
  SeparationsRealTimePage
} from '../pages';

export function PrivateRoutes() {
  return <div>
    <Route exact path={paths.dashboard} component={DashboardPage}></Route>
    <Route exact path={paths.clients} component={ClientsPage}></Route>
    <Route exact path={paths.companies} component={CompaniesPage}></Route>
    <Route exact path={paths.deliveries} component={DeliveriesPage}></Route>
    <Route exact path={paths.me} component={MePage}></Route>
    <Route exact path={paths.mePassword} component={MePasswordPage}></Route>
    <Route exact path={paths.reports} component={ReportsPage}></Route>
    <Route exact path={paths.restrictedDates} component={RestrictedDatesPage}></Route>
    <Route exact path={paths.stores} component={StoresPage}></Route>
    <Route exact path={paths.users} component={UsersPage}></Route>
    <Route exact path={paths.vehicles} component={VehiclesPage}></Route>
    <Route exact path={paths.regions} component={RegionsPage}></Route>
    <Route exact path={paths.separations} component={SeparationsPage}></Route>
    <Route exact path={paths.separationsRealTime} component={SeparationsRealTimePage}></Route>
    <Route exact path={paths.sectors} component={SectorsPage}></Route>
  </div>
}
