import React, { createContext, useCallback, useContext, useState, useEffect } from 'react';
import { NotificationsRepository } from '../repositories';
import { createConsumer } from '@rails/actioncable';
import { Constants } from '../helpers';
import { useSelector } from 'react-redux';
import { NotificationModel } from '../models';
import { useSnackbar } from 'notistack';

const NotificationContext = createContext({
	isNotificationOpen: false,
	notifications: [],
	total: 0,
	totalUnread: 0,

	toggleNotificationOpen: () => { },
	fetchNotifications: () => { },
	nextPage: () => { },
	viewNotification: () => { },
	deleteAllNotifications: () => { },
	deleteNotification: () => { },
	viewAllNotifications: () => { }
});

export const useNotificationContext = () => {
	return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { auth } = useSelector(state => state);
	const [isNotificationOpen, setIsNotificationOpen] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [total, setTotal] = useState(0);
	const [totalUnread, setTotalUnread] = useState(0);
	const [page, setPage] = useState(1);
	const [channel, setChannel] = useState(null);
	const notificationsRepository = new NotificationsRepository();
	const cable = createConsumer(`${Constants[process.env.NODE_ENV].wsUrl}cable`);

	const toggleNotificationOpen = useCallback(() => {
		setIsNotificationOpen(oldNotificationOpen => !oldNotificationOpen);
	}, []);

	const fetchNotifications = async () => {
		const { total, data, totalUnread } = await notificationsRepository.getAll({ queryParams: "target_type=Delivery", page });
		if (total > 0) {
			setTotal(parseInt(total));
			setTotalUnread(parseInt(totalUnread));
			const mergedNotifications = [...notifications, ...data];
			const uniqueNotifications = Array.from(new Set(mergedNotifications.map(n => n.id)))
				.map(id => mergedNotifications.find(n => n.id === id));
			const sortedNotifications = uniqueNotifications.sort((a, b) => a.viewedAt && new Date(a.createdAt) < new Date(b.createdAt));
			setNotifications(sortedNotifications);
		}
	};

	const viewAllNotifications = async () => {
		await notificationsRepository.viewAll();
		const updatedNotifications = notifications.map(item => {
			if (!item.viewedAt) {
				item.viewedAt = new Date();
			}
			setTotalUnread(0);
			return item;
		});
		setNotifications(updatedNotifications);
	}

	const deleteAllNotifications = async () => {
		await notificationsRepository.deleteAll();
		setNotifications([]);
		setTotal(0);
		setTotalUnread(0);
	}

	const deleteNotification = async (id) => {
		await notificationsRepository.delete(id);
		const not = notifications.findIndex(item => item.id === id);
		notifications.splice(not, 1);
		setTotal(total - 1);
		setNotifications([...notifications]);
	}

	const nextPage = () => {
		if (notifications.length >= total) return
		setPage(oldPage => oldPage + 1);
	};

	const viewNotification = async (notification) => {
		if (notification.viewedAt) return;

		await notificationsRepository.view(notification.id);
		const not = notifications.findIndex(item => item.id === notification.id);
		notifications[not].viewedAt = new Date();
		setNotifications([...notifications]);
		setTotalUnread(totalUnread - 1);
	}

	useEffect(() => {
		fetchNotifications();
	}, [page]);

	const addNotification = useCallback((notification) => {
		const newNotification = NotificationModel.fromJSON(notification);
		setNotifications(prevNotifications => [newNotification, ...prevNotifications]);
		setTotal(prevTotal => prevTotal + 1);
		setTotalUnread(prevTotalUnread => prevTotalUnread + 1);
	}, []);

	useEffect(() => {
		if (channel !== null) channel.unsubscribe();
		setChannel(cable.subscriptions.create(
			{ channel: 'NotificationsChannel', user_id: auth.id },
			{
				received: (notification) => {
					switch (notification.target_type) {
						case "Delivery":
							enqueueSnackbar(notification.title, Constants.SnackBar.Info);
							addNotification(notification);
							break;
						default:
							break;
					}
				}
			},
		))
	}, []);

	return (
		<NotificationContext.Provider value={{
			isNotificationOpen,
			notifications,
			total,
			totalUnread,

			toggleNotificationOpen,
			fetchNotifications,
			nextPage,
			viewNotification,
			deleteAllNotifications,
			deleteNotification,
			viewAllNotifications
		}}>
			{children}
		</NotificationContext.Provider>
	);
};
