import React, { useEffect, useState } from "react";
import { Grid, Typography, MenuItem, List, ListItem } from "@material-ui/core";
import { useSelector } from "react-redux";

import {
  DateInputFieldComponent,
  NumberPhoneInputFieldComponent,
  TextInputFieldComponent,
  BackButton,
  SaveButton,
  VisibilityIconButton
} from "../../../shared/components";

import { UsersRepository } from "../../../shared/repositories";
import { UserModel } from "../../../shared/models";
import { authorizeChecker, Constants } from "../../../shared/helpers";
import { useDrawerRightContext } from "../../../shared/contexts/drawer_right.context";
import { UsersAutoCompleteSector } from "./users_auto_complete_sector.component";

export function UsersDetailComponent({ update }) {
  const auth = useSelector(state => state.auth);
  const [user, setUser] = useState({});
  const usersRepository = new UsersRepository();
  const [viewPassword, setViewPassword] = useState(false)
  const [chiefDrivers, setChiefDrivers] = useState([])
  const { drawerValue, closeDrawerRight } = useDrawerRightContext();
  const roles = Constants.User.Roles.filter(role => role.label !== "all");
  const authorizedRoles = {
    [Constants.User.Role.System]: roles,
    [Constants.User.Role.Owner]: roles.filter(role => !["system"].includes(role)),
    [Constants.User.Role.Admin]: roles.filter(role => !["owner", "system"].includes(role)),
    [Constants.User.Role.Checker]: roles.filter(role => !["admin", "owner", "system"].includes(role)),
    [Constants.User.Role.Seller]: roles.filter(role => !["admin", "owner", "system"].includes(role)),
    [Constants.User.Role.ChiefDriver]: roles.filter(role => role.label === Constants.User.Role.Driver),
  }

  const saveUser = _ => {
    const userToSave = new UserModel(user);
    return usersRepository[userToSave.id ? 'update' : 'create'](userToSave.toJSON())
      .then(data => {
        update(data)
        setUser(data.toState())
      });
  }

  useEffect(() => {
    if (chiefDrivers.length == 0) {
      usersRepository.getAll({ queryParams: `roles=${Constants.User.Role.ChiefDriver}`, perPage: 1000 })
        .then(result => {
          setChiefDrivers(result.data)
        })
    }
    setUser(drawerValue)
  }, [drawerValue])

  const handleChange = name => event => {
    setUser({ ...user, [name]: event.target.value });
  };

  const goBack = () => {
    closeDrawerRight()
  }

  const handleDateChange = name => date => {
    setUser({ ...user, [name]: date ? date._d : null });
  };

  const handleChangeSectors = options => {
    setUser({ ...user, sectors: options });
  };


  const listRoles = () => {
    var roles = authorizedRoles[auth.role];

    return roles.map((option) => (
      <MenuItem key={option.label} value={option.label}>
        {option.value}
      </MenuItem>
    ))
  }

  return (
    <List>
      <ListItem>
        <Typography variant="h6" gutterBottom>Usuário</Typography>
      </ListItem>
      <ListItem>
        <TextInputFieldComponent
          id="legacyUserId"
          label="Código legado"
          disabled={!!user.id}
          value={user.legacyUserId}
          onChange={handleChange('legacyUserId')}
        />
      </ListItem>
      <ListItem>
        <TextInputFieldComponent
          id="status-select"
          label="Status"
          select
          value={user.status}
          disabled={!authorizeChecker(auth)}
          onChange={handleChange("status")}
          fullWidth>
          {Constants.User.Statuses.filter(status => status.label !== "all").map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {option.value}
            </MenuItem>
          ))}
        </TextInputFieldComponent>
      </ListItem>
      {user.id ?
        <ListItem>
          <TextInputFieldComponent
            id="username"
            label="Username"
            disabled
            value={user.username}
          />
        </ListItem>
        : null}
      <ListItem>
        <TextInputFieldComponent
          id="emailAddress"
          label="E-mail"
          value={user.emailAddress}
          onChange={handleChange('emailAddress')}
        />
      </ListItem>
      <ListItem>
        <TextInputFieldComponent
          id="cpf"
          label="Documento(CPF)"
          value={user.document}
          onChange={handleChange('document')}
        />
      </ListItem>
      <ListItem>
        <TextInputFieldComponent
          id="name"
          label="Nome"
          value={user.name}
          onChange={handleChange('name')}
        />
      </ListItem>
      <ListItem>
        <NumberPhoneInputFieldComponent
          value={user.phoneNumber || ""}
          onChange={handleChange('phoneNumber')}
        />
      </ListItem>
      <ListItem>
        <TextInputFieldComponent
          id="role-select"
          label="Tipo"
          select
          value={user.role}
          disabled={auth.role === Constants.User.Role.ChiefDriver}
          onChange={handleChange("role")}
          fullWidth>
          {listRoles()}
        </TextInputFieldComponent>
      </ListItem>
      {user.role === Constants.User.Role.Separator && <ListItem>
        <UsersAutoCompleteSector user={user} onChange={handleChangeSectors} />
      </ListItem>}
      {user.role === Constants.User.Role.Driver ?
        <ListItem>
          {<TextInputFieldComponent
            id="parent-id"
            variant="outlined"
            select
            label="Responsável"
            disabled={!authorizeChecker(auth)}
            value={user.parentId}
            onChange={handleChange("parentId")}>
            <MenuItem key={"all"} value={"all"}>Nenhum</MenuItem>
            {chiefDrivers.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextInputFieldComponent>}
        </ListItem>
        : null
      }
      {[Constants.User.Role.Driver, Constants.User.Role.ChiefDriver].includes(user.role) && <>
        <ListItem>
          <TextInputFieldComponent
            id="cnhNumber"
            label="Número da CNH"
            value={user.cnhNumber}
            onChange={handleChange('cnhNumber')}
          />
        </ListItem>
        <ListItem>
          <Typography variant="body1" gutterBottom>Categorias CNH</Typography>
        </ListItem>
        <ListItem>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhAcc"
              label="ACC"
              value={user.cnhAcc}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhAcc")}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhA"
              label="A"
              value={user.cnhA}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhA")}
            />
          </Grid>
        </ListItem>

        <ListItem>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhA1-select"
              label="A1"
              value={user.cnhA1}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhA1")}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhB-select"
              label="B"
              value={user.cnhB}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhB")}
            />
          </Grid>
        </ListItem>

        <ListItem>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhB1-select"
              label="B1"
              value={user.cnhB1}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhB1")}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhC-select"
              label="C"
              value={user.cnhC}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhC")}
            />
          </Grid>
        </ListItem>

        <ListItem>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhC1-select"
              label="C1"
              value={user.cnhC1}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhC1")}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhD-select"
              label="D"
              value={user.cnhD}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhD")}
            />
          </Grid>
        </ListItem>

        <ListItem>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhD1-select"
              label="D1"
              value={user.cnhD1}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhD1")}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhBe-select"
              label="BE"
              value={user.cnhBe}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhBe")}
            />
          </Grid>
        </ListItem>

        <ListItem>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhCe-select"
              label="CE"
              value={user.cnhCe}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhCe")}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhC1e-select"
              label="C1E"
              value={user.cnhC1e}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhC1e")}
            />
          </Grid>
        </ListItem>
        <ListItem>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhDe-select"
              label="DE"
              value={user.cnhDe}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhDe")}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <DateInputFieldComponent
              id="cnhD1e-select"
              label="D1E"
              value={user.cnhD1e}
              error={false}
              helperText={null}
              onChange={handleDateChange("cnhD1e")}
            />
          </Grid>
        </ListItem>

      </>}
      {
        !user.id ?
          <>
            <ListItem>
              <TextInputFieldComponent
                id="password-select"
                label="Senha"
                type={viewPassword ? "text" : "password"}
                value={user.password}
                onChange={handleChange('password')}
                InputProps={{
                  endAdornment: <VisibilityIconButton view={viewPassword} onClick={() => setViewPassword(!viewPassword)} />
                }}
              />
            </ListItem>
            <ListItem>
              <TextInputFieldComponent
                id="password-confirmation-select"
                label="Confirmar Senha"
                type={viewPassword ? "text" : "password"}
                value={user.passwordConfirmation}
                onChange={handleChange('passwordConfirmation')}
                InputProps={{
                  endAdornment: <VisibilityIconButton view={viewPassword} onClick={() => setViewPassword(!viewPassword)} />
                }}
              />

            </ListItem>
          </>
          :
          <>
            <ListItem>
              <Typography variant="body1" gutterBottom>Datas</Typography>
            </ListItem>
            <ListItem>
              <TextInputFieldComponent
                id="createdAt-select"
                label="Criado em"
                disabled
                value={user.createdAtFormatted}
              />
            </ListItem>
            <ListItem>
              <TextInputFieldComponent
                id="updatedAt-select"
                label="Atualizado em"
                disabled
                value={user.updatedAtFormatted}
              />
            </ListItem>
          </>
      }

      <ListItem style={{ display: "flex", justifyContent: "space-between" }}>
        <BackButton onClick={goBack} />
        <SaveButton onClick={saveUser} disabled={!authorizeChecker(auth)} />
      </ListItem>
    </List >
  );

}
