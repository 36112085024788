import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react'
import { SnackbarProvider } from 'notistack';

import { Routes, HistoryRoutes } from './Routes';
import { store, persistor } from './shared/store';
import { SnackbarCloseIconButton } from './shared/components';
import { DrawerRightProvider, NotificationProvider } from './shared/contexts';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={HistoryRoutes}>
        <SnackbarProvider maxSnack={3} autoHideDuration={2000} action={snackbarKey => <SnackbarCloseIconButton snackbarKey={snackbarKey} />} dense>
          <NotificationProvider>
            <DrawerRightProvider>
              <Routes />
            </DrawerRightProvider>
          </NotificationProvider>
        </SnackbarProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
