import { NotificationModel } from "../models";
import { AuthRepository } from "./auth.repository";
import { Repository } from "./repository";

export class NotificationsRepository extends Repository {
  constructor() {
    super();
    this.authRepository = new AuthRepository();
  }

  async getAll({ queryParams = "", page = 1, perPage = 10, orderBy = "name", orderDirection = "asc" } = {}) {
    return this.api.get({ path: `notifications?q=${queryParams}&page=${page}&per_page=${perPage}&order_by=${orderBy}&order_direction=${orderDirection}`, headers: this.headers() })
      .then(response => {
        return Promise.resolve({
          data: NotificationModel.fromArray(response.data),
          total: response.headers['total'],
          totalUnread: response.headers['total-unread-notifications']
        });
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.getAll.bind(this),
        callbackOnError: () => { this.dispatch({ type: "CLEAR_NOTIFICATION" }) }
      }));
  }

  async view(id) {
    return this.api.put({ path: `notifications/${id}`, headers: this.headers(), body: { viewed_at: new Date() } })
      .then(response => {
        return Promise.resolve(NotificationModel.fromJSON(response.data));
      }
      ).catch(error => this.resolveErrorResponse({
        error,
        callback: this.update
      }));
  }

  async viewAll() {
    return this.api.post({ path: `notifications/all`, headers: this.headers() })
      .then(response => {
        return Promise.resolve(NotificationModel.fromJSON(response.data));
      }
      ).catch(error => this.resolveErrorResponse({
        error,
        callback: this.update
      }));
  }

  async deleteAll() {
    return this.api.delete({ path: `notifications/all`, headers: this.headers() })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.delete.bind(this),
      }));
  }

  async delete(id) {
    return this.api.delete({ path: `notifications/${id}`, headers: this.headers() })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => this.resolveErrorResponse({
        error,
        callback: this.delete.bind(this),
      }));
  }
}
